<template>
  <div class="comments-section">
    <div class="comment-input">
      <div class="user-image">
        <img :src="userInformation.profile_picture" alt="User image" />
      </div>
      <div class="send-comment">
        <textarea
          id=""
          placeholder="Post comment"
          v-model="addComment"
          @input="autoResize"
        ></textarea>
        <the-loader v-if="loading" class="comment-textarea-loader"></the-loader>
        <button class="send-btn" :disabled="loading" @click="createComment()">
          <i class="icon icon-send"></i>
        </button>
      </div>
    </div>
  </div>
  <article-comments
    v-for="(comment, index) in comments"
    :key="index"
    :profilePicture="comment.user.profile_picture"
    :fullName="comment.user.full_name"
    :comment="comment.comment"
    :commentsReplies="comment.child"
    :storyId="comment.article_data"
    :parentId="comment.id"
    :displayPicture="userInformation.profile_picture"
    :time="comment.human_time"
    :commentBy="comment.user.id"
    :commentFor="'discover'"
    :commentDetails="comment"
  ></article-comments>
</template>

<script>
import ArticleComments from "./Comments.vue";
import TheLoader from "../TheLoader.vue";

export default {
  props: {
    articleId: String,
  },
  components: {
    ArticleComments,
    TheLoader,
  },
  data() {
    return {
      addComment: "",
      loading: false,
      textareaDiv: null
    };
  },
  computed: {
    comments() {
      return this.$store.getters["discover/getDiscoverArticleComments"];
    },
    userInformation() {
      return this.$store.getters["profile/userInformation"].profile;
    },
  },
  methods: {
    async createComment() {
      this.loading = true;
      try {
        const payload = {
          comment: this.addComment,
          parent: null,
          article_id: this.articleId,
        };
        const response = await this.$store.dispatch(
          "discover/discoverArticleComment",
          payload
        );
        // if (response.status === 201) {
          this.addComment = null;
          // Reset Textarea Height
          this.textareaDiv.style.height = 'auto'
          this.$showToast({message:'Comment posted successfully!', type:'success'})
          this.fetchComments();
        // }
      } catch (err) {
        console.log(err);
        this.$showToast({message:'Something went wrong. Please try again later.', type:'success'})
      }
      this.loading = false;
    },
    async fetchComments() {
      try{
        await this.$store.dispatch("discover/fetchDiscoverArticleComments", {
          article_id: this.articleId,
        });
      }
      catch(err){
        this.$showToast({message:'Could not load the comments. Please try again later.', type:'failure'})
      }
    },
    autoResize(event) {
      this.textareaDiv = event.target;
      this.textareaDiv.style.height = "auto"; // Reset height
      this.textareaDiv.style.height = `${this.textareaDiv.scrollHeight}px`; // Set new height based on content
    },
  },
};
</script>

<style lang="scss">
.comments-section {
  border-top: 0.2rem solid rgba(128, 128, 128, 0.158);
  padding: 0.5rem 0 1rem;
  .comment-input {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 2rem 0 1rem;
    .user-image {
      width: 3.5rem;
      height: 3.5rem;
      border-radius: 100%;
      margin-right: 0.9rem;
      background-color: rgb(238 237 237 / 63%);
      img {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        background-color: rgb(238 237 237 / 63%);
      }
    }
    .send-comment {
      width: calc(100% - 4.4rem);
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      background-color: rgb(238 237 237 / 63%);
      padding: 1.5rem;
      border-radius: 0.5rem;
      height: max-content;
      textarea {
        width: 100%;
        background-color: transparent;
        border: none;
        outline: none;
        font-size: 1.6rem;
        font-family: $font-primary;
        line-height: 2rem;
        overflow: hidden;
        resize: none;
        &::placeholder {
          opacity: 0.7;
        }
      }
      .send-btn {
        border: none;
        height: fit-content;
        width: fit-content;
        line-height: normal;
        margin-left: 1rem;
        background-color: transparent;
        cursor: pointer;
        .icon {
          color: $color-primary;
          font-size: 2rem;
        }
      }
    }
  }
}
</style>
